import { useLocation, useNavigate } from "react-router-dom";
import Page from "../../skeletons/Page";
import Highlight from "../../elements/atoms/Highlight";
import { useCallback, useEffect } from "react";
import useAxios from "../../../api/useAxios";
import { useToast } from "../../elements/atoms/toast/ToastContext";
 

function Activate() {
  const axios = useAxios()
  const location = useLocation();
  const navigate = useNavigate()
  const { addToast, delToast } = useToast()

  const token = new URLSearchParams(location.search).get('token');

  const activate = useCallback(async () => {
    const toastId = addToast("Activating account ...", "info", 0);
    try {
      await axios.post("/v1/account/activate", {token});
      delToast(toastId)
      navigate("/account/activate-success")
    } catch (error: any) {
      delToast(toastId)
      addToast(error.response.data.error, "error");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, token])

  useEffect(() => {
    activate()
  }, [activate])

  if(token === undefined){
    navigate("/")
    return <div></div>
  }

  return (
    <Page>
      <div className="text-center w-full">
        <div className="text-3xl">
          <Highlight>Activating account ... </Highlight>
        </div>
        <p className="max-w-[90vw] m-auto mt-4 text-gray-400">
          Please wait while your account is being activated, it can take up to 10 seconds.
         </p>
      </div>
    </Page>
  );
}

export default Activate;
