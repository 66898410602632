import React from "react";

// TypeScript type for the props of the Time component
interface TimeProps {
  seconds: string;
}

const Time: React.FC<TimeProps> = ({ seconds }) => {
  // Function to calculate the time ago string
  const getTime = (seconds: string): string => {
    const difference = Number(seconds) // Difference in seconds

    if (difference < 60) {
      return `${difference} seconds`;
    } else if (difference < 3600) {
      return `${Math.floor(difference / 60)} minutes`;
    } else if (difference < 86400) {
      return `${Math.floor(difference / 3600)} hours`;
    } else {
      return `${Math.floor(difference / 86400)} days`;
    }
  };

  return <span>{getTime(seconds)}</span>;
};

export default Time;
