import Panel from "../../../elements/atoms/Panel";
import Button from "../../../elements/atoms/Button";
import Time from "../../../elements/atoms/Time";
import { useCallback } from "react";
import { useConfirm } from "../../../elements/atoms/confirm/ConfirmContext";
import { useToast } from "../../../elements/atoms/toast/ToastContext";
import useAxios from "../../../../api/useAxios";

interface TileProps {
  hashlist: any;
  job: any;
  offer: any;
  onAcceptOffer?: () => void;
  className?: string;
}

const JobOfferTile: React.FC<TileProps> = (props) => {
  const { className, offer, onAcceptOffer } = props;
  const { offerName, candidates, offerDesc, cost, estimatedTime } = offer;

  const axios = useAxios()
  const confirm = useConfirm()
  const { addToast } = useToast()

  const prettyCandidates = candidates ? new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(candidates) : "";


  const prettyCost = candidates ? new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(cost) : "";

  let accentColor = "";
  let title = "";
  let description = "";
  if (offerName === "MEDIUM") {
    title = "MEDIUM BLAST";
    description =
      "Unleash the power of up to 60 RTX 4090s, splitting tasks like a pro. Get your hashes cracked with a balance of speed and savings!";
    accentColor = "purple";
  }
  if (offerName === "HIGH") {
    title = "HIGH VOLTAGE";
    description =
      "Step up to 80 RTX 4090s for even faster hash-cracking! It’s like turning the dial up to eleven – for those who need their answers sooner.";
    accentColor = "yellow";
  }
  if (offerName === "EXTREME") {
    title = "EXTREME FURY";
    description =
      "Buckle up for the ultimate ride with up to 140 RTX 4090s working in harmony! For those who demand nothing short of cracking records in record time.";
    accentColor = "red";
  }

  const acceptOffer = useCallback(async () => {
    const res = await confirm(`Accept the "${title}" offer ?`, `This will cost you ${cost} HRC`)
    if(res) {
      try{
        const resp = await axios.post(`/v1/hashlist/${props.hashlist.id}/job/accept-offer`, {
          offer: offerName,
          context: offer.context,
        })
        addToast(JSON.stringify(resp.data))
        if(onAcceptOffer){
          onAcceptOffer()
        }
      } catch (e: any) {
        addToast(e.response.data.error, "error");
      }
    } else {
      addToast("Operation cancelled", "info")
    }
  }, [addToast, axios, confirm, cost, offerName, onAcceptOffer, props.hashlist.id, props.job, title])

  return (
    <Panel className={[className, "relative align-top!"].join(" ")}>
      <div
        style={{
          boxShadow: "inset 0 0 30px 0px var(--tw-shadow-color, purple)",
        }}
        className={[
          "flex-1", 
          "absolute top-0 left-0 right-0 bottom-0 rounded",
          "opacity-20",
          `shadow-${accentColor}-500`,
        ].join(" ")}
      ></div>
      <div className="p-2">
        <div className="text-white">
          <div className={["text-3xl font-bold", `text-${accentColor}-500`].join(" ")}>{title}</div>
          <div className="text-sm text-gray-500">{description}</div>
        </div>
        <div
          className={["border-b m-4", `border-${accentColor}-500`].join(" ")}
        ></div>
        <div>
          <div className="m-2">
            <div className={["font-bold", `text-${accentColor}-500`].join(" ")}>
              {offerDesc}
            </div>
            <div className="text-sm text-gray-500">
              Will be set up to complete your job.
            </div>
          </div>
          <div className="m-2">
            <div className={["font-bold", `text-${accentColor}-500`].join(" ")}>
              <Time seconds={estimatedTime} />
            </div>
            <div className="text-sm text-gray-500">
              Estimated time to complete your job.
            </div>
          </div>
          <div className="m-2">
            <div className={["font-bold", `text-${accentColor}-500`].join(" ")}>
              {prettyCandidates} candidates
            </div>
            <div className="text-sm text-gray-500">
              Tested candidates over your hashlist.
            </div>
          </div>          
        </div>
        <div className="my-4 mt-8">
          <div className="text-5xl text-center text-white font-bold">
            {prettyCost}
          </div>
          <div className="text-center">
            HRC
          </div>
        </div>
      </div>
      <div className="p-4 pt-0">
      <Button onClick={acceptOffer}  color={accentColor} className={["w-full"].join(" ")}>
        Accept
      </Button>
      </div>
      <div className="hidden shadow-yellow-500 shadow-red-500 shadow-purple-500" />
      <div className="hidden border-yellow-500 border-red-500 border-purple-500" />
      <div className="hidden text-yellow-500 text-red-500 text-purple-500" />
      <div className="hidden bg-yellow-500 bg-red-500 bg-purple-500" />
    </Panel>
  );
};

export default JobOfferTile;
