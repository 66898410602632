import Home from "./components/pages/Home";
import Login from "./components/pages/account/Login";
import Register from "./components/pages/account/Register";
import Activate from "./components/pages/account/Activate";
import RegisterSuccess from "./components/pages/account/RegisterSuccess";
import ActivateSuccess from "./components/pages/account/ActivateSuccess";
import Dashboard from "./components/pages/app/Dashboard";
import Logout from "./components/pages/account/Logout";
import Hashlists from "./components/pages/app/Hashlists";
import Hashlist from "./components/pages/app/Hashlist";
import Wordlists from "./components/pages/app/Wordlists";
import Rulesets from "./components/pages/app/Rulesets";
import Challenge from "./components/pages/Challenge";
import NotFound from "./components/pages/NotFound";
import { Navigate } from "react-router-dom";

export const routes = [
  {
    path: "/",
    title: "Hash Republic",
    description:
      "Hash Republic allows users to rent powerful GPUs for Hashcat jobs. Join us for fast, efficient, and reliable hash-cracking services.",
    element: <Home />,
  },
  {
    path: "/challenge",
    title: "Hash Republic - Challenge",
    description:
      "Take on hash reversal challenges, crack the code, and win prizes. Test your skills at Hash Republic!",
    element: <Challenge />,
  },
  {
    path: "/account/login",
    title: "Hash Republic - Login",
    noIndex: true,
    element: <Login />,
  },
  {
    path: "/account/logout",
    title: "Hash Republic - Logout",
    noIndex: true,
    element: <Logout />,
  },
  {
    path: "/account/register",
    title: "Hash Republic - Register",
    noIndex: true,
    element: <Register />,
  },
  {
    path: "/account/register-success",
    title: "Hash Republic - Register success",
    noIndex: true,
    element: <RegisterSuccess />,
  },
  {
    path: "/account/activate",
    title: "Hash Republic - Activate",
    noIndex: true,
    element: <Activate />,
  },
  {
    path: "/account/activate-success",
    title: "Hash Republic - Activate success",
    noIndex: true,
    element: <ActivateSuccess />,
  },
  // ===== START APP =====
  {
    path: "/app",
    title: "Hash Republic - App",    
    noIndex: true,
    element: <Navigate to={"/app/dashboard"} />,
  },
  {
    path: "/app/dashboard",
    title: "Hash Republic - Dashboard",
    noIndex: true,
    element: <Dashboard />,
  },
  {
    path: "/app/hashlists",
    title: "Hash Republic - Hashlists",
    noIndex: true,
    element: <Hashlists />,
  },
  {
    path: "/app/hashlist/:id",
    title: "Hash Republic - Hashlist",
    noIndex: true,
    element: <Hashlist />,
  },
  {
    path: "/app/wordlists",
    title: "Hash Republic - Wordlists",
    noIndex: true,
    element: <Wordlists />,
  },
  {
    path: "/app/rulesets",
    title: "Hash Republic - Rulesets",
    noIndex: true,
    element: <Rulesets />,
  },
  {
    path: "/404",
    title: "Hash Republic - Not Found",
    noIndex: true,
    element: <NotFound />,
  },
  {
    path: "*",
    noIndex: true,
    element: <Navigate to={"/404"} />,
  },
];
