import { useCallback, useState } from "react";
import Form from "../../../elements/atoms/form/Form";
import { FormProvider } from "../../../elements/atoms/form/FormContext";
import InputText from "../../../elements/atoms/form/input/InputText";
import Panel from "../../../elements/atoms/Panel";
import Button from "../../../elements/atoms/Button";
import InputAttackMode from "../../../elements/atoms/form/input/InputAttackMode";
import { useToast } from "../../../elements/atoms/toast/ToastContext";
import InputWordlist from "../../../elements/atoms/form/input/InputWordlist";
import useAxios from "../../../../api/useAxios";
import InputRuleset from "../../../elements/atoms/form/input/InputRuleset";
import JobOfferTile from "./JobOfferTile";
import InputHashrepublicAttackMode from "../../../elements/atoms/form/input/InputHashrepublicAttackMode";

interface TileProps {
  hashlist: any;
  onAcceptOffer?: () => void;
  className?: string;
}

const NewJobTile: React.FC<TileProps> = (props) => {
  const [job, setJob] = useState(null);
  const [offers, setOffers] = useState<any[]|null>(null);
  const { addToast, delToast } = useToast()

  const { onAcceptOffer } = props;
  const [attackMode, setAttackMode] = useState<string>("0")
  const [hrAttackMode, setHrAttackMode] = useState<string>("MASK")
  const axios = useAxios()

  const acceptOffer = useCallback(() => {
    setOffers(null)
    if(onAcceptOffer){
      onAcceptOffer()
    }
  }, [onAcceptOffer])


  const onSubmit = async (values: any) => {
    const toastId = addToast("Looking for offers ...", "info", 0);
    try {
      const resp = await axios.post(`/v1/hashlist/${props.hashlist.id}/job/get-offers`, values)
      setJob(values)
      setOffers(resp.data.offers)
      if(resp.data.offers.length === 0){
        delToast(toastId)
        addToast("Can't get offers for the specified job", "error")
      } else {
        delToast(toastId)
        addToast("Successfully retrieved offers", "success")
      }
      // addToast(JSON.stringify(resp.data), "info", 10000)
    } catch (e: any) {
      delToast(toastId)
      addToast(e.response.data.error, "error");
    }
  };
  return (
    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-4">
    <Panel title="Create a new job" className="m-2 md:col-span-1 lg:col-span-1">
      <FormProvider onAnyFieldChange={() => setOffers(null)} onSubmit={onSubmit}>
        <Form>
          <InputAttackMode name="attack" label="Attack mode" defaultValue={attackMode} onChange={(newVal) => {
            setAttackMode(newVal)
          }}/>
          {["HR"].includes(attackMode) && <InputHashrepublicAttackMode name="hrm" label="Hash Republic Attack" defaultValue={hrAttackMode} onChange={(newVal) => {
            setHrAttackMode(newVal)
          }} />}           
          {["HR"].includes(attackMode) && ["LOOP"].includes(hrAttackMode) && <div className="text-xs italic text-gray-300">
            Before running <b className="text-gray-500">Hash Republic Loop (HRL)</b>, ensure you have sufficient amount of already recovered potfile, a good starting point is at least 20%. <br/>
            Starting an HRL attack without sufficient reversed hashes will not be efficient. <br/>
            Once the HRL attack become inneficient, retrieving new hashes may make it efficient again.
            </div>} 
            {["HR"].includes(attackMode) && ["MASK"].includes(hrAttackMode) && <div className="text-xs italic text-gray-300">
              <div className="text-center p-2">
                <span className="text-xs font-medium me-2 px-2.5 py-0.5 rounded bg-purple-900 text-purple-300">BETA FEATURE</span>
              </div>
              <div className="p-2">
              Before running <b className="text-gray-500">Hash Republic Mask (HRM)</b>, ensure you have sufficient amount of already recovered potfile, a good starting point is at least 20%. <br/>
              Starting an HRL attack without sufficient reversed hashes will not be efficient. <br/>
              </div>
            </div>}             
          {["0"].includes(attackMode) && <InputRuleset name="rules" label="Ruleset"/>} 
          {["3", "6", "7"].includes(attackMode) && <InputText name="cs1" label="Custom charset 1" />} 
          {["3", "6", "7"].includes(attackMode) && <InputText name="cs2" label="Custom charset 2" />} 
          {["3", "6", "7"].includes(attackMode) && <InputText name="cs3" label="Custom charset 3" />} 
          {["3", "6", "7"].includes(attackMode) && <InputText name="cs4" label="Custom charset 4" />} 
          {["0", "1", "6"].includes(attackMode) && <InputWordlist name="arg1" label="Wordlist 1"/>} 
          {["3", "7"].includes(attackMode) && <InputText name="arg1" label="Mask 1" />} 
          {["6"].includes(attackMode) && <InputText name="arg2" label="Mask 2" />} 
          {["1", "7"].includes(attackMode) && <InputWordlist name="arg2" label="Wordlist 2"/>}           
          <Button className="mt-4 w-full"> Find offers </Button>
        </Form>
      </FormProvider>    
      </Panel>
      {offers && offers.map((offer) => {
        return <JobOfferTile hashlist={props.hashlist} job={job} onAcceptOffer={acceptOffer} className="m-2" key={offer.offerName} offer={offer}/>
      })}
      {offers === null && (<div className="col-span-3 flex items-center text-center italic text-gray-600">
            <div className="text-center w-full"> Offers will appear here </div>
        </div>)}
      {offers && offers.length === 0 && (<div className="col-span-3 flex items-center text-center italic text-gray-600">
        <div className="text-center w-full"> No offers for the asked job </div>
      </div>)}        
    </div>
  );
};

export default NewJobTile;
