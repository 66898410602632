import { useState } from "react";
import Panel from "../../../elements/atoms/Panel";
import { FormProvider } from "../../../elements/atoms/form/FormContext";
import Form from "../../../elements/atoms/form/Form";
import PaypalButton from "../../../elements/atoms/payment/PaypalButton";
import InputSelect from "../../../elements/atoms/form/input/InputSelect";

interface TileProps {
  className?: string;
  onAddCredit?: () => void;
}

const AddCreditTile: React.FC<TileProps> = (props) => {
  const [amount, setAmount] = useState<string>("25");

  const amountOptions = [
    { value: "5", label: "5 USD" },
    { value: "10", label: "10 USD" },
    { value: "25", label: "25 USD" },
    { value: "50", label: "50 USD" },
    { value: "100", label: "100 USD" },
    { value: "250", label: "250 USD" },
    { value: "500", label: "500 USD" },
    { value: "1000", label: "1000 USD" },
  ];

  return (
    <Panel
      title="Add credit"
      className={["inline-block", props.className].join(" ")}
    >
      <FormProvider>
        <Form>
          <InputSelect
            options={amountOptions}
            defaultValue={amount}
            name="amount"
            label="Amount (1 USD > 1 HRC)"
            onChange={(newVal: string) => setAmount(newVal)}
          />
          <div className="p-2">
            <PaypalButton amount={amount} onApprove={props.onAddCredit} />
          </div>
        </Form>
      </FormProvider>
    </Panel>
  );
};

export default AddCreditTile;
