import React from "react";

// TypeScript type for the props of the PrettyTime component
interface PrettyTimeProps {
  timestamp: number;
}

const PrettyTime: React.FC<PrettyTimeProps> = ({ timestamp }) => {
  // Function to calculate the time ago string
  const getPrettyTime = (t: number): string => {
    if (t < 60) {
      return `${t} seconds`;
    } else if (t < 3600) {
      return `${Math.floor(t / 60)} minutes`;
    } else if (t < 86400) {
      return `${Math.floor(t / 3600)} hours`;
    } else {
      return `${Math.floor(t / 86400)} days`;
    }
  };

  return <span>{getPrettyTime(timestamp)}</span>;
};

export default PrettyTime;
