import { useCallback, useEffect, useState } from "react";
import Page from "../../skeletons/App/Page";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../api/useAxios";
import Button from "../../elements/atoms/Button";
import Placeholder from "../../elements/atoms/Placeholder";
import TimeAgo from "../../elements/atoms/TimeAgo";
import { useToast } from "../../elements/atoms/toast/ToastContext";
import NewJobTile from "./Tiles/NewJobTile";
import { useConfirm } from "../../elements/atoms/confirm/ConfirmContext";
import useDownload from "../../../api/useDownload";
import { useHashcatModes, withHashcatMode } from "../../elements/atoms/provider/HashcatModesProvider";
import JobTile from "./Tiles/JobTile";
import { withAuthGuard } from "../../elements/atoms/auth/AuthGuard";
import { withRuleset } from "../../elements/atoms/provider/RulesetProvider";
import { withWordlist } from "../../elements/atoms/provider/WordlistProvider";


function Hashlist() {
  const [hashlist, setHashlist] = useState<any | null>(null);
  const [jobs, setJobs] = useState<any[] | null>(null);
  const [showAddJob, setShowAddJob] = useState<boolean>(false)
  const axios = useAxios();
  const { id } = useParams<{ id: string }>();
  const { addToast, delToast } = useToast()
  const confirm = useConfirm()
  const navigate = useNavigate()
  const download = useDownload()
  const { modeAsString } = useHashcatModes()

  const loadHashlist = useCallback(async () => {
    try {
      const resp = await axios.get(`/v1/hashlist/${id}`);
      setHashlist(resp.data);
    } catch (error) {
      addToast("Could not load hashlist", "error");
      navigate("/app/hashlists")
    }
  }, [axios, id, addToast, navigate]);

  const loadJobs = useCallback(async () => {
    try {
      const resp = await axios.get(`/v1/hashlist/${id}/job/list`);
      setJobs(resp.data);
    } catch (error) {
      addToast("Could not load jobs", "error");
    }
  }, [axios, id, addToast]);

  const showDeleteModal = async () => {
    const result = await confirm(
      "Are you sure you want to delete hashlist ?",
      "This action can't be undone"
    )
    if (result) {
      const toastId = addToast("Deleting haslist", "info", 0);
      try {
         await axios.delete(`/v1/hashlist/${id}`, {
          responseType: "blob", // Important for binary data
        });
        delToast(toastId)
        addToast('Successfully deleted hashlist!', 'success');
        navigate("/app/hashlists")
      } catch (error) {
        delToast(toastId)
        addToast("Error deleting hashlist", "error");
      }
    } else {
      addToast('Delete action cancelled!');
    }
  }

  const onAcceptOffer = () => {
    loadJobs()
    setShowAddJob(false)
  }

  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (initialized) {
      return;
    }
    (async function () {
      setInitialized(true);
      await loadHashlist();
      await loadJobs();
    })();
  });

  const prettyCount = hashlist ? new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(hashlist.count) : "";
  const prettyReversed = hashlist ? new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(hashlist.reversed) : "";
  const prettyProgress = hashlist ? new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format((100 * (hashlist.reversed)) / hashlist.count) : "";

 
  const topbarAfterContent = hashlist && (<div className=" w-full opacity-60 mt-1 top-1 relative">
    <div className="bg-gray-800 rounded">
      <div 
        style={{width: `${100 * hashlist.reversed / hashlist.count}%`}}
        className="from-[#ff4200] to-[#ba00b6] bg-gradient-to-bl rounded h-1" />
    </div>
  </div>)

  return (
    <Page topbarAfterContent={topbarAfterContent}>
      <div className="p-8 flex">
        <div className="flex-1">
          <div className="text-3xl font-bold">
            {hashlist ? hashlist.label : <Placeholder />}
          </div>
          <div className="text-xl">
            {hashlist ? modeAsString(hashlist.hashcatMode) : <Placeholder className="w-[60%]"/>} <br/>
            {hashlist ? <span>{prettyCount} hashes</span> : <Placeholder/>}       
            </div>
          <div>
          {hashlist ? <span>{prettyReversed} recovered ({prettyProgress}%)</span> : <Placeholder className="w-[55%]"/>}
          </div>
          <div className="text-sm leading-[1em] text-gray-400">
            { hashlist ? <span>Created : <TimeAgo timestamp={hashlist.createdAt} /></span> : <Placeholder className="w-[80%]"/>} <br/>
            { hashlist ? <span>Updated : <TimeAgo timestamp={hashlist.updatedAt} /></span> : <Placeholder className="w-[85%]"/>}
          </div>
          <div>
          </div>
        </div>
      </div>
      <div className="text-center">
        <Button color="blue" type="outlined" onClick={() => download(`/v1/hashlist/${hashlist.id}/download-hashlist`, `${hashlist.label}.hashes.gz`)}> Download hashlist </Button> 
        <Button color="blue" type="outlined" onClick={() => download(`/v1/hashlist/${hashlist.id}/download-potfile`, `${hashlist.label}.pot.gz`)}> Download potfile </Button> 
        <Button color="green" type="outlined" onClick={() => setShowAddJob(!showAddJob)}> Show/hide new job form </Button>
        <Button color="red" type="outlined" onClick={showDeleteModal}> Delete </Button>

        </div>      
      {showAddJob && <div className="p-4">
        <NewJobTile onAcceptOffer={onAcceptOffer} hashlist={hashlist}/>
      </div>}
      { jobs && <div className="p-4">
        {jobs.length === 0 && <div>
              No jobs added yet !
          </div>}
          {jobs.length !== 0 && <div>
            {jobs.map((job) => {
              return <JobTile key={job.id} hashlist={hashlist} job={job}></JobTile>
            })}
          </div>}          
      </div> }

 
    </Page>
  );
}

export default withAuthGuard(withHashcatMode(withRuleset(withWordlist(Hashlist))));
