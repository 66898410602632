import React from "react";
import { AiOutlineClose } from "react-icons/ai";


interface ModalProps {
  isOpened: boolean
  setIsOpened: (opened: boolean) => void;
  children: React.ReactNode; // children can be any valid React node
}

const Modal: React.FC<ModalProps> = (props) => {
    const { isOpened, children, setIsOpened } = props

 

  return <div className={[
    "fixed flex items-center top-0 left-0 bottom-0 right-0 bg-black text-gray-400 bg-opacity-80 z-10 transition-all",
    (isOpened ? "" : "pointer-events-none opacity-0")
  ].join(' ')
  }>
    <div className="text-center w-full p-4">
        <div className={[
            "inline-block rounded shadow-lg transition-all", 
            
        ].join(" ")} style={{
            transform: (isOpened ? "scale(1)" : "scale(0.0001)")
        }}>
            <div className="absolute top-3.5 right-3.5 cursor-pointer text-gray-500 hover:text-gray-300" onClick={() => setIsOpened(false)}>
                <AiOutlineClose/>
            </div>
            {children}
        </div>
    </div>
  </div>;
};

export default Modal;
