import Panel from "../../../elements/atoms/Panel";

interface TileProps {
  className?: string;
}

const CompanyNoticeTile: React.FC<TileProps> = (props) => {
 

  return (
    <Panel title="Company notice" className={["inline-block", props.className].join(" ")}>
      <div className="p-2">
      If you would like to request an invoice or discuss specific requirements for our services, please feel free to reach out to us at <a className="inline-block opacity-50 hover:opacity-70 active:opacity-100" href="mailto:contact@hashrepublic.net">contact@hashrepublic.net</a>. Our team is ready to assist with tailored solutions to meet your needs.
      </div>
    </Panel>
  );
};

export default CompanyNoticeTile;
