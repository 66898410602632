import PrettySpeed from "../../../elements/atoms/PrettySpeed";
import PrettyTime from "../../../elements/atoms/PrettyTime";
import Progress from "../../../elements/atoms/Progress";

interface TileProps {
  hashlist: any;
  job: any;
  task: any;
  className?: string;
}

const TaskTile: React.FC<TileProps> = (props) => {
  const {  task } = props;
 
 

  const prettyPercentProgress = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(task.progress) 


  const prettyReversed = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(task.reversed) 

  let bg = "bg-gray-900"
  if(task.status === "ERROR"){
    bg = "bg-gradient-to-bl from-gray-950 to-red-950"
  }

  return (
    <div className={[bg, "border border-black flex-col rounded-md shadow-md text-white px-2 pt-0 pb-2 m-1"].join(" ")}>
      <div className="flex items-center">
        <div className="flex-1 flex items-center">         
          <span className="font-bold text-gray-500 p-1"> {prettyPercentProgress}% </span>
          <span className="text-gray-600 text-xs px-2">{task.status}</span>
        </div>
        <div className="text-gray-600 text-xs">
        {task.speed > 0 && <><PrettySpeed speed={task.speed}/> | </>}
        {task.eta > 0 && <><PrettyTime timestamp={task.eta}/> remaining | </>}           
        { prettyReversed } reversed
        </div>
      </div>
      {task.status === "ERROR" && (
        <div className="text-gray-600 text-xs px-4">
          The task has crashed, it will be restarted soon 
        </div>
      )}
      {task.status !== "ERROR" && (
        <div>
          <Progress total={100} progress={task.progress}/>
        </div>
      )}
      {/* <div className="text-gray-600 text-center py-2">
      <a target="_blank" className="text-xs" href={url} rel="noreferrer">{url}</a>   <br/>
      </div> */}
    </div>
   ); 
};

export default TaskTile;
