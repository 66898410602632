import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
} from "react";
import { jwtDecode } from "jwt-decode";
import { getApiClient } from "../../../../api/apiClient";

interface AuthContextType {
  accessToken: string | null;
  isLoggedIn: () => boolean;
  tokenClaims: () => any;
  login: (
    username: string,
    password: string,
    rememberMe: boolean
  ) => Promise<void>;
  logout: () => Promise<void>;
  refreshAccessToken: () => Promise<string>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [accessToken, setAccessToken] = useState<string | null>(
    localStorage.getItem("accessToken") || 
    sessionStorage.getItem("accessToken")
  );
  const [refreshToken, setRefreshToken] = useState<string | null>(
    localStorage.getItem("refreshToken") ||
    sessionStorage.getItem("refreshToken")
  );

  const login = async (
    username: string,
    password: string,
    rememberMe: boolean
  ) => {
    const apiClient = getApiClient();
    const resp = await apiClient.post("/v1/account/login", {
      username,
      password,
    });

    const { accessToken, refreshToken } = resp.data;

    if (rememberMe) {
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
    } else {
      sessionStorage.setItem("accessToken", accessToken);
      sessionStorage.setItem("refreshToken", refreshToken);
    }
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
  };

  const logout = async () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("refreshToken");
    setAccessToken(null);
    setRefreshToken(null);
    window.location.reload();
  };

  const isLoggedIn = () => {
    return !!accessToken;
  };

  const tokenClaims = () => {
    if (!isLoggedIn()) {
      return {};
    }
    return jwtDecode(accessToken!);
  };

  const refreshAccessToken = async () => {
    const apiClient = getApiClient();
    try {
      const resp = await apiClient.post("/v1/account/refresh", {
        token: refreshToken,
      });
      const { accessToken } = resp.data;
      setAccessToken(accessToken);
      if (localStorage.getItem("refreshToken")) {
        localStorage.setItem("accessToken", accessToken);
      } else if (sessionStorage.getItem("refreshToken")) {
        sessionStorage.setItem("accessToken", accessToken);
      }
      return accessToken;
    } catch (e) {
      logout();
    }
  };

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        isLoggedIn,
        tokenClaims,
        login,
        logout,
        refreshAccessToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const withAuth = <P extends object>(Component: React.ComponentType<P>): React.FC<P> => {
  return (props: P) => (
    <AuthProvider>
      <Component {...props} />
    </AuthProvider>
  );
};