import React from "react";
import UserWidget from "./UserWidget";

 
interface TopbarProps {
  topbarAfterContent?: React.ReactNode;
 }

const Topbar: React.FC<TopbarProps> = ({topbarAfterContent}) => {
 
  return (
    <div className="bg-black p-4 text-white relative h-full rounded-lg shadow-lg">
      <div className="flex">
        <div className="flex-1">

        </div>
        <UserWidget/>
      </div>
      {topbarAfterContent}
    </div>
  );
};

export default Topbar;
