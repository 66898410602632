import React from "react";

// TypeScript type for the props of the PrettySpeed component
interface PrettySpeedProps {
  speed:number;
}

const PrettySpeed: React.FC<PrettySpeedProps> = ({ speed }) => {
  // Function to calculate the time ago string
  const getPrettySpeed = (s:number) => {
    const units = ['H/s', 'kH/s', 'MH/s', 'GH/s', 'TH/s', 'PH/s'];
    const index = Math.floor(Math.log10(s) / 3); // Determine the appropriate unit index
    const formattedSpeed = (s / Math.pow(1000, index)).toFixed(2); // Format speed
    return `${formattedSpeed} ${units[index]}`;
};


  return <span>{getPrettySpeed(speed)}</span>;
};

export default PrettySpeed;
