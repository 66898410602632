import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../elements/atoms/auth/AuthContext";
import { withAuthGuard } from "../../elements/atoms/auth/AuthGuard";


function Logout() {

  const navigate = useNavigate()
  const { logout } = useAuth();
 
  useEffect(() => {
    (async function(){
      await logout()
      navigate("/")
    })()
  }, [logout, navigate])

  return (
    <div>
    </div>
  );
}

export default withAuthGuard(Logout);
