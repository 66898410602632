// AuthGuard.tsx
import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Import the useAuth hook

// Define the props for the AuthGuard component
interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
  const { isLoggedIn } = useAuth(); // Get authentication status from context

  // If the user is not authenticated, redirect to the home page
  if (!isLoggedIn()) {
    return <Navigate to="/" replace />;
  }

  // If the user is authenticated, render the protected content
  return <>{children}</>;
};

export default AuthGuard;


export const withAuthGuard = <P extends object>(Component: React.ComponentType<P>): React.FC<P> => {
  return (props: P) => (
    <AuthGuard>
      <Component {...props} />
    </AuthGuard>
  );
};