import React from "react";
import Topbar from "./Page/Topbar";
import Helmet from "../elements/helmet/Helmet";
interface PageProps {
  className?: string;
  full?: boolean;
  children: React.ReactNode; // children can be any valid React node
}

const Page: React.FC<PageProps> = ({ children, full }) => {
  return (
    <>
    <Helmet/>
    <div className="h-screen text-gray-300">
      <div className="fixed top-0 left-0 h-full w-full bg-gray-900">
        <div className="absolute top-0 left-0 h-full w-full from-10% from-[#ff4200] via-[#ba00b6] via-70% bg-gradient-to-tr mix-blend-overlay"></div>
      </div>
      <div className="flex min-h-full flex-col">
        <Topbar></Topbar>
        {full ? (
          <main className="relative flex-1 flex w-full mx-auto p-y2 overflow-y-auto">
            {children}
          </main>
        ) : (
          <main className="relative   flex-1 flex items-center container mx-auto p-2 overflow-y-auto">
            {children}
          </main>
        )}

        <footer className="relative p-2 text-gray-600 bg-black bg-opacity-20 p-4 mt-12">
          <div className="container flex items-center w-full mx-auto">
            <p className="w-full text-left italic">
              <div className=" text-gray-400 text-xs">
                By using this platform, you acknowledge and agree to comply with
                our{" "}
                <a
                  className="inline-block opacity-50 hover:opacity-70 active:opacity-100"
                  href="/terms_and_conditions.md"
                >
                  Terms and Conditions
                </a>
                .
              </div>
            </p>
            <p className="w-full text-right">&copy; 2024 Hash Republic</p>
          </div>
        </footer>
      </div>
    </div>
    </>
  );
};

export default Page;
