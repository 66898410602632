import Page from "../../skeletons/Page";
import Button from "../../elements/atoms/Button";
import InputText from "../../elements/atoms/form/input/InputText";
import { FormProvider } from "../../elements/atoms/form/FormContext";
import Form from "../../elements/atoms/form/Form";
import * as yup from "yup";
import InputPassword from "../../elements/atoms/form/input/InputPassword";
import Highlight from "../../elements/atoms/Highlight";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../elements/atoms/auth/AuthContext";
import InputBoolean from "../../elements/atoms/form/input/InputBoolean";
import Panel from "../../elements/atoms/Panel";
import { useToast } from "../../elements/atoms/toast/ToastContext";


function Login() {
  const navigate = useNavigate()
  const { addToast, delToast } = useToast()

  const validationSchema = yup.object().shape({
    username: yup.string().required("Username is required"),
    password: yup
      .string()
      .required("Password is required")
  });

  const { login } = useAuth();

  const onSubmit = async (values: any) => {
    const toastId = addToast("Logging in ...", "info", 0);

    try {
      await login(values.username, values.password, values.rememberMe)
      delToast(toastId)
      addToast("Successfully logged in", "success");
      navigate("/app")
    } catch (error: any) {
      delToast(toastId)
      addToast(error.response.data.error, "error");
    }
  };

  return (
    <Page> 
      <div className="text-center w-full">
        <FormProvider validationSchema={validationSchema} onSubmit={onSubmit}>
          <Form>
            <Panel className="m-4 inline-block">
              <Highlight className=" text-2xl  font-bold ">Login</Highlight>
              <div className="text-gray-500">Welcome back!</div>
              <div className="my-4">
                <div className="inline-block max-w-[400px]">
                  <InputText label="Username" name="username" />
                  <InputPassword label="Password" name="password" />
                  <InputBoolean label="Remember me" name="rememberMe" />
                  <Button className="mt-4"> Login </Button>
                </div>
              </div>
            </Panel>
          </Form>
        </FormProvider>
      </div>
    </Page>
  );
}

export default Login;
