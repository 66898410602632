import React, { useCallback } from "react";
import InputSelect from "./InputSelect";

const InputHashrepublicAttackMode: React.FC<{
  name: string;
  label: string;
  defaultValue: string;
  onChange?: (newValue: string) => void;
}> = ({ name, label, defaultValue, onChange }) => {
  const options = [
    { label: "MASK - Mask", value: "MASK" },
    // { label: "LOOP - Loop", value: "LOOP" },
  ];

  

  const handleOnChange = useCallback((newVal: string) => {
    if(onChange){
      onChange(newVal)
    }
  }, [onChange])


  return (
    <InputSelect
      name={name}
      label={label}
      options={options}
      defaultValue={defaultValue}
      onChange={handleOnChange}
    />
  );
};

export default InputHashrepublicAttackMode;
