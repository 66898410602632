import { useCallback, useEffect, useState } from "react";
import useAxios from "../../../../api/useAxios";
import { useToast } from "../../../elements/atoms/toast/ToastContext";
import TaskTile from "./TaskTile";
import { useWordlist } from "../../../elements/atoms/provider/WordlistProvider";
import { useRuleset } from "../../../elements/atoms/provider/RulesetProvider";
import PrettyTime from "../../../elements/atoms/PrettyTime";
import PrettySpeed from "../../../elements/atoms/PrettySpeed";

interface TileProps {
  hashlist: any;
  job: any;
  className?: string;
}

const JobTile: React.FC<TileProps> = (props) => {
  const { hashlist, job } = props;
  const [showTasks, setShowTasks] = useState<boolean>(false);
  const [tasks, setTasks] = useState<any[] | null>(null);

  const axios = useAxios();
  const { addToast } = useToast();

  const loadTasks = useCallback(async () => {
    try {
      const resp = await axios.get(
        `/v1/hashlist/${hashlist.id}/job/${job.id}/task/list`
      );
      setTasks(resp.data);
    } catch (error) {
      addToast("Could not load tasks", "error");
    }
  }, [axios, hashlist.id, job.id, addToast]);

  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (initialized) {
      return;
    }
    (async function () {
      setInitialized(true);
      await loadTasks();
    })();
  });

  const prettyReversed = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(job.reversed) 

  const prettyCost = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(job.cost) 

  let badge: any = "";
  let bgClasses = ""
  switch (job.status) {
    case "CREATED":
      bgClasses = "from-blue-500 to-blue-900 bg-gradient-to-bl blur-lg"
      badge = (
        <div className="inline-block bg-blue-900 text-blue-300 text-xs font-medium px-2.5 py-0.5 rounded ">
          {" "}
          CREATED{" "}
        </div>
      );
      break;
    case "FINISHED":
      bgClasses = "from-green-500 to-green-900 bg-gradient-to-bl blur-lg"
      badge = (
        <div className="inline-block bg-green-900 text-green-300 text-xs font-medium px-2.5 py-0.5 rounded ">
          {" "}
          FINISHED{" "}
        </div>
      );
      break;
    default:
      bgClasses = "from-yellow-500 to-yellow-900 bg-gradient-to-bl blur-lg"
      badge = (
        <div className="inline-block bg-yellow-900 text-yellow-300 text-xs font-medium px-2.5 py-0.5 rounded ">
          {" "}
          WORKING{" "}
        </div>
      );
      break;
  }

  const { getName: getWordlistName } = useWordlist()
  const { getName: getRulesetName } = useRuleset()
  const args = job.args.replace(/wordlist1/, () => {
    return getWordlistName(`${job.wordlist1}`)
  }).replace(/wordlist2/, () => {
    return getWordlistName(`${job.wordlist2}`)
  }).replace(/rules/, () => {
    return getRulesetName(`${job.rules}`)
  })  

  return (
    <div className="p-2">
      <div 
        onClick={() => setShowTasks(!showTasks)}
        className="bg-gray-950 border relative border-black flex-col overflow-hidden rounded-md shadow-md text-white px-4 pt-1 pb-1 m-1">
        <div className={[" absolute opacity-20 top-0 left-0 bottom-0 z-0", bgClasses].join(" ")} style={{width: `${job.progress}%`}}>
        </div>  
        <div className="relative">
          <div className="flex items-center flex-col md:flex-row">
            <div className="flex-1">
              <div className="md:inline-block text-center relative top-[-1px]">{badge}</div>
              <div className="inline-block px-2 text-sm text-gray-600">
                {job.engine} {args}
              </div>
            </div>
            <div className="text-right text-gray-600 text-xs">
            {job.speed > 0 && <><PrettySpeed speed={job.speed}/> | </>}
            {job.eta > 0 && <><PrettyTime timestamp={job.eta}/> remaining | </>}
            {job.mode} |&nbsp;
            {prettyCost} HRC |&nbsp;
              {prettyReversed} reversed
            </div>
          </div>
        </div>
      </div>
      {showTasks && tasks && (
        <div className="p-4">
          {tasks.map((task) => {
            return (
              <TaskTile
                key={task.id}
                hashlist={hashlist}
                job={job}
                task={task}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default JobTile;
