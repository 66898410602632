import { useCallback } from "react";
import { withAuthGuard } from "../../elements/atoms/auth/AuthGuard";
import Page from "../../skeletons/App/Page";
import AddCreditTile from "./Tiles/AddCreditTile";
import BalanceTile from "./Tiles/BalanceTile";
import CompanyNoticeTile from "./Tiles/CompanyNoticeTile";
import { useToast } from "../../elements/atoms/toast/ToastContext";



function Dashboard() {

  const { addToast } = useToast() 

  const onAddCredit = useCallback(() => {
    addToast("Successfully added credit to your balance.\nThe page will now refresh!", "success", 10000)
    setTimeout(() => {
      window.location.reload()
    }, 5000)
  }, [addToast])

  return (
    <Page>    
      <div className="grid md:grid-cols-2 lg:grid-cols-3">
        <BalanceTile className="m-2"/>
        <AddCreditTile onAddCredit={onAddCredit} className="m-2"/> 
        <CompanyNoticeTile className="m-2"/>
      </div>
    </Page>
  );
}

export default withAuthGuard(Dashboard);
