import React, { createContext, useState, useEffect, useContext, useCallback } from "react";
import useAxios from "../../../../api/useAxios";

// Define the context structure
interface WordlistContextType {
  options: { label: string; value: string }[];
  getName: (id: string) => string
  refreshOptions: () => Promise<void>;
}

// Create the context
const WordlistContext = createContext<WordlistContextType | undefined>(undefined);

// Custom hook to use WordlistContext
export const useWordlist = () => {
  const context = useContext(WordlistContext);
  if (!context) {
    throw new Error("useWordlist must be used within a WordlistProvider");
  }
  return context;
};

// Helper function to check if a refresh is needed (24 hours or more)
const isRefreshNeeded = () => {
  const lastFetchTime = localStorage.getItem("lastFetchTimeRulesetOptions");
  if (!lastFetchTime) return true; // No timestamp means data should be fetched

  const currentTime = new Date().getTime();
  const oneDayInMs = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
  return currentTime - parseInt(lastFetchTime, 10) > oneDayInMs;
};

// Provider component
export const WordlistProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [options, setOptions] = useState<{ label: string; value: string }[]>([]);
  const axios = useAxios()

  const fetchOptions = useCallback(async () => {
    try {
      const resp = await axios.get("/v1/wordlist/list");  
      const formattedOptions = [{
        label: "Self", 
        value: "SELF",
      },...resp.data.filter((wl:any) => {
        return wl.canUse
      }).map((item: any) => ({
        label: item.label,
        value: item.id
      }))];

      // Cache in localStorage and update state
      localStorage.setItem("WordlistOptions", JSON.stringify(formattedOptions));
      localStorage.setItem("lastFetchTimeRulesetOptions", new Date().getTime().toString()); // Save the current time
      setOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching wordlist options:", error);
    }
  }, [axios]);

  // Function to refresh options
  const refreshOptions = async () => {
    await fetchOptions();
  };

  const getName = (id: string) => {
    // eslint-disable-next-line eqeqeq
    return options.find((opt) => opt.value == id)?.label || "<unknown>"
  }

  useEffect(() => {
    const cachedOptions = localStorage.getItem("WordlistOptions");
    const diff = (cachedOptions !== JSON.stringify(options))
    if (cachedOptions && !isRefreshNeeded()) {
        if(diff){
            setOptions(JSON.parse(cachedOptions)); // Use cached data if it's still valid
        }
    } else {
      fetchOptions(); // Fetch new data if the cache is outdated
    }
  }, [fetchOptions, options]);

  return (
    <WordlistContext.Provider value={{ options, getName, refreshOptions }}>
      {children}
    </WordlistContext.Provider>
  );
};

export const withWordlist = <P extends object>(Component: React.ComponentType<P>): React.FC<P> => {
  return (props: P) => (
    <WordlistProvider>
      <Component {...props} />
    </WordlistProvider>
  );
};