import React, { createContext, useState, useEffect, useContext, useCallback } from "react";
import useAxios from "../../../../api/useAxios";

// Define the context structure
interface HashcatModeContextType {
  modes: any[];
  getMode: (mode: string) => any,
  modeAsString: (mode: string) => string,
  refreshModes: () => Promise<void>;
}

// Create the context
const HashcatModeContext = createContext<HashcatModeContextType | undefined>(undefined);

// Custom hook to use HashcatModeContext
export const useHashcatModes = () => {
  const context = useContext(HashcatModeContext);
  if (!context) {
    throw new Error("useHashcatModes must be used within a HashcatModeProvider");
  }
  return context;
};

// Helper function to check if a refresh is needed (24 hours or more)
const isRefreshNeeded = () => {
  const lastFetchTime = localStorage.getItem("lastFetchTimeHashcatModeOptions");
  if (!lastFetchTime) return true; // No timestamp means data should be fetched

  const currentTime = new Date().getTime();
  const oneDayInMs = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
  return currentTime - parseInt(lastFetchTime, 10) > oneDayInMs;
};

// Provider component
export const HashcatModeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [modes, setModes] = useState<any[]>([]);
  const axios = useAxios()

  const fetchModes = useCallback(async () => {
    try {
      const resp = await axios.get("/v1/hashmodes");  
 
      // Cache in localStorage and update state
      localStorage.setItem("HashcatModeOptions", JSON.stringify(resp.data));
      localStorage.setItem("lastFetchTimeHashcatModeOptions", new Date().getTime().toString()); // Save the current time
      setModes(resp.data);
    } catch (error) {
      console.error("Error fetching HashcatMode options:", error);
    }
  }, [axios]);

  // Function to refresh options
  const refreshModes = async () => {
    await fetchModes();
  };


   const getMode = (mode: string) => {
      return modes.find((m:any) => `${m.Mode}` === mode)
  }
  
   const modeAsString = (mode: string) => {
      const hashcatMode = getMode(`${mode}`)
      if(hashcatMode === null || hashcatMode === undefined){
          return "Unknown hashcat mode"
      }
      return `${hashcatMode.Mode} - ${hashcatMode.Name}`   
  }  

  useEffect(() => {
    const cachedModes = localStorage.getItem("HashcatModeOptions");
    const diff = (cachedModes !== JSON.stringify(modes))
    if (cachedModes && !isRefreshNeeded()) {
      if(diff){
        setModes(JSON.parse(cachedModes)); // Use cached data if it's still valid
      }
    } else {
      fetchModes(); // Fetch new data if the cache is outdated
    }
  }, [fetchModes, modes]);

  return (
    <HashcatModeContext.Provider value={{ modes, getMode, modeAsString, refreshModes }}>
      {children}
    </HashcatModeContext.Provider>
  );
};


export const withHashcatMode = <P extends object>(Component: React.ComponentType<P>): React.FC<P> => {
  return (props: P) => (
    <HashcatModeProvider>
      <Component {...props} />
    </HashcatModeProvider>
  );
};